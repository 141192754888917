import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p><img parentName="p" {...{
        "src": "https://img.shields.io/gitlab/last-commit/41621129?label=Updated",
        "alt": "GitLab last commit"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "https://img.shields.io/gitlab/contributors/41621129",
        "alt": "GitLab contributors"
      }}></img>{`
`}<a parentName="p" {...{
        "href": "https://twitter.com/intelAnalytics_"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/twitter/follow/intelAnalytics_?style=social",
          "alt": "Twitter Follow"
        }}></img></a></p>
    <p>{`Welcome to the Intelligence Analytics Wiki, here you will find documentation on how to read our data and understand the language we use.`}</p>
    <h2 {...{
      "id": "how-does-it-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-does-it-work",
        "aria-label": "how does it work permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does it work?`}</h2>
    <p>{`You supply us with keywords, job titles, countries and company linkedin Url's and we return back to you a tailored report on those companies/company. It's that easy!`}</p>
    <h2 {...{
      "id": "about-us",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#about-us",
        "aria-label": "about us permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`About us`}</h2>
    <p>{`IA is a company we started in 2019 to solve 1 problem:`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`create`}</strong>{` visibility where there is `}<strong parentName="p">{`none`}</strong>{`.`}</p>
    </blockquote>
    <p>{`It took a whole year to develop the first working algorithm and generate `}<a parentName="p" {...{
        "href": "/reports/casefile/"
      }}>{`Casefiles`}</a>{`, using our methods we were able to create far better insight into companies and people than anyone else. Our whole ethos is to see things differently and through continued innovation, we are helping organisations change their view on business intelligence.`}</p>
    <h2 {...{
      "id": "why-make-a-wiki",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-make-a-wiki",
        "aria-label": "why make a wiki permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why make a wiki?`}</h2>
    <p>{`We decided to create this Wiki because we are on the bleeding edge of new techniques in business intelligence and (although we'd love to be) we can't be around 24/7 to explain some of the language and terms we use. It also serves as a guide as well on how to read our data too and get the most out of the stunning visuals we produce. We are a highly visual company and our success lies in our customer's ability to understand and make the full value of the data and graphs we provide.`}</p>
    <h2 {...{
      "id": "language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#language",
        "aria-label": "language permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Language`}</h2>
    <p>{`We often get asked questions about some of the language we use here at IA, this is a complete guide so you always know what we are talking about during those cool demos and browsing intelligence.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Sentiment`}</strong>{` - `}<strong parentName="p">{`" a view or opinion that is held or expressed"`}</strong>{` often found in our Sentiment graphs where people point to terms made of merged intelligence entities from a final data graph.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Correlation`}</strong>{` - `}<strong parentName="p">{`" a mutual relationship or connection between two or more things"`}</strong>{` our Correlation graphs track how people behave in relation to each other by interacting with intelligence entities from a final data graph.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Entity`}</strong>{` - A `}<strong parentName="p">{`node`}</strong>{` or circular orb, usually varying in size on the graphs we use.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "a-nice-photo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-nice-photo",
        "aria-label": "a nice photo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A nice photo`}</h2>
    <p>{`If you made it this far enjoy this photo!`}</p>
    <p><img parentName="p" {...{
        "src": "https://wiki.i-a.cloud/cdn-cgi/imagedelivery/YZO2dKh2-nQCM3E2pZE7Kw/4dce22b5-8fbd-47f1-33ab-0ac1450e1c00/512",
        "alt": "Horby Island in Canada"
      }}></img></p>
    {/* <img
      loading="lazy"
      alt="Photo by dirk von loen-wagner"
      src="https://images.unsplash.com/photo-1516132006923-6cf348e5dee2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80"
     /> */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      